import * as React from 'react'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import Layout from '../components/Layout'
import Link from '../components/Link'
import { Container, Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import Imgix from 'react-imgix';
import SearchEngineOptimization from '../components/SearchEngineOptimization'

import FolderIcon from '@material-ui/icons/Folder';

import { unified } from "unified"
import parse from "rehype-parse"
import rehypeReact from "rehype-react"
import Pagenation from '../components/Pagenation'
import "../components/style.css"
import { Facebook, Twitter } from '@material-ui/icons'
import moment from "moment"

export const query = graphql`
query ($id: String!) {
  microcmsBlog(id: {eq: $id}) {
    id
    category {
      categoryTitle
      categorySlug
      descPriority
    }
    title
    slug
    publishedAt
    revisedAt
    fields {
      scheduledAt
      blogDescription
    }
    eyecatch {
      url
      height
      width
    }
    content
  }
}
`

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 5,
  },
  heroFigure: {
    maxHeight: "100%",
    height: "350px",
    margin: 0,
    zIndex: 1,
  },
  heroFigureImg: {
    width: "100%",
    height: "350px",
    objectFit: "cover",
    objectPosition: "0 30%",
  },
  categoryList: {
    listStyle: "none",
    display: "inline-flex",
    margin: 0,
    padding: 0,
  },
  categories: {
    textAlign: "right",
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
  share: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: `${theme.palette.primary.main}`,
    textDecoration: `none`,
    '&:hover': {
      textDecoration: `underline`
    }
  },

}))



const BlogPage = ({ data, pageContext, location }) => {
  const classes = useStyles()
  const siteMetadata = pageContext.siteMetadata
  const categories = pageContext.categories
  const url = `${siteMetadata.siteUrl}/blog/${data.microcmsBlog.slug || data.microcmsBlog.id}`
  const descCategory = data.microcmsBlog.category
    .sort((a, b) => (b.descPriority - a.descPriority))
    .find(cat => cat.descPriority > 0)
  const descCategoryTitle = descCategory ? ` | ${descCategory.categoryTitle}` : ``
  const buildTimeISO = pageContext.buildTimeISO
  const isPast = data.microcmsBlog.fields.hasScheduledAt ? data.microcmsBlog.fields.scheduledAt < buildTimeISO : false

  console.log(`${location.pathname} - ${buildTimeISO} - ${data.microcmsBlog.fields.scheduledAt} - ${isPast}`)

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    Fragment: React.Fragment,
    components: {
      img: props => (
        <figure>
          <Imgix src={props.src}
            htmlAttributes={{
              alt: `${props.alt}`
            }}
          />
        </figure>
      )
    },
  }).Compiler


  const htmlAst = unified()
    .use(parse, { fragment: true })
    .parse(data.microcmsBlog.content)

  return (
    <Layout
      siteMetadata={siteMetadata}
      categories={categories}
    >
      <SearchEngineOptimization
        pagetitle={`${data.microcmsBlog.title}${descCategoryTitle}`}
        pagedesc={`${data.microcmsBlog.fields.blogDescription}`}
        pagepath={`/blog/${data.microcmsBlog.slug || data.microcmsBlog.id}`}
        hero={data.microcmsBlog.eyecatch}
      />

      <article>
        <header>
          {isPast &&
            <Typography variant="h3">{`※この記事は、過去の記事です。`}</Typography>
          }
          <Typography variant="h4">{data.microcmsBlog.title}</Typography>
          <Container style={{ display: `flex`, justifyContent: `end` }}>
            <div className={classes.wrapIcon}>
              <FolderIcon />
              <ul className={classes.categoryList}>
                {data.microcmsBlog.category.map((c, idx) => (
                  <li key={c.category}>
                    {idx > 0 && `／`}
                    <Link to={`/${c.categorySlug}`} variant="subtitle1">
                      {c.categoryTitle}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Container>
          <Paper className={classes.hero}>
            <figure className={classes.heroFigure}>
              <Imgix className={classes.heroFigureImg}
                src={data.microcmsBlog.eyecatch.url}
                htmlAttributes={{
                  alt: `記事「${data.microcmsBlog.title}」のメイン画像`
                }}
              />
            </figure>
          </Paper>
        </header>
        <div className="content">
          {renderAst(htmlAst)}
        </div>
        <div style={{ marginBottom: `1rem` }}>
          <a className={classes.share} target="_blank" rel="noreferrer noopener" href={`https://twitter.com/intent/tweet?url=${url}`}><Button variant="contained" color="secondary"><Twitter fontSize="small" />&nbsp;share</Button></a>
          <a className={classes.share} target="_blank" rel="noreferrer noopener" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}><Button variant="contained" color="secondary"><Facebook fontSize="small" />&nbsp;share</Button></a>
        </div>

        <Typography component="div" variant="body2">
          <time datetime={data.microcmsBlog.publishedAt}>
            {`投稿日時: ${moment(data.microcmsBlog.publishedAt).format("YYYY年MM月DD日 HH:mm")}`}
          </time>
          {data.microcmsBlog.publishedAt !== data.microcmsBlog.revisedAt &&
            <time datetime={data.microcmsBlog.revisedAt}>
              {`／更新日時: ${moment(data.microcmsBlog.revisedAt).format("YYYY年MM月DD日 HH:mm")}`}
            </time>
          }
        </Typography>
      </article>
      <Pagenation next={pageContext.next} previous={pageContext.previous} categoryTitle={pageContext.categoryTitle} categorySlug={pageContext.categorySlug} />
    </Layout >
  )
}


export default BlogPage
